import { useState, useEffect } from 'react';
import { db } from '@/lib/firebase';
import { collection, query, where, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { useFirebase } from '@/lib/context/firebase-context';

export const useLikes = (profileIds: string[]) => {
  const [likes, setLikes] = useState<{ [key: string]: { isLiked: boolean; count: number } }>({});
  const [loading, setLoading] = useState(true);
  const { user } = useFirebase();

  // Fetch like status and count for all profiles
  useEffect(() => {
    const fetchLikes = async () => {
      if (!profileIds.length) return;

      try {
        const likesRef = collection(db, 'likes');
        const newLikes: { [key: string]: { isLiked: boolean; count: number } } = {};

        // Initialize likes object
        profileIds.forEach(id => {
          newLikes[id] = { isLiked: false, count: 0 };
        });

        // Get all likes for these profiles
        const likesQuery = query(
          likesRef,
          where('profileId', 'in', profileIds)
        );
        const likesSnapshot = await getDocs(likesQuery);

        // Count likes and check user's likes
        likesSnapshot.forEach(doc => {
          const data = doc.data();
          const profileId = data.profileId;
          
          if (!newLikes[profileId]) {
            newLikes[profileId] = { isLiked: false, count: 0 };
          }
          
          newLikes[profileId].count++;
          
          if (user && data.userId === user.uid) {
            newLikes[profileId].isLiked = true;
          }
        });

        setLikes(newLikes);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching likes:', error);
        setLoading(false);
      }
    };

    if (profileIds.length > 0) {
      fetchLikes();
    }
  }, [profileIds, user]);

  const toggleLike = async (profileId: string) => {
    if (!user) return;

    try {
      const likesRef = collection(db, 'likes');
      const userLikeQuery = query(
        likesRef,
        where('profileId', '==', profileId),
        where('userId', '==', user.uid)
      );
      const userLikeSnapshot = await getDocs(userLikeQuery);

      if (userLikeSnapshot.empty) {
        // Add like
        await addDoc(likesRef, {
          profileId,
          userId: user.uid,
          createdAt: new Date().toISOString()
        });
        setLikes(prev => ({
          ...prev,
          [profileId]: {
            isLiked: true,
            count: (prev[profileId]?.count || 0) + 1
          }
        }));
      } else {
        // Remove like
        const likeDoc = userLikeSnapshot.docs[0];
        await deleteDoc(doc(db, 'likes', likeDoc.id));
        setLikes(prev => ({
          ...prev,
          [profileId]: {
            isLiked: false,
            count: Math.max((prev[profileId]?.count || 1) - 1, 0)
          }
        }));
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  return { likes, loading, toggleLike };
}; 