'use client';
import { motion, useMotionValue, useTransform, PanInfo } from "framer-motion";
import Image from "next/image";
import React, { useState, useEffect } from "react";

interface SwipeCardProps {
  children: React.ReactNode;
  onSendToBack: (direction: 'left' | 'right') => void;
}

function SwipeCard({ children, onSendToBack }: SwipeCardProps) {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const [shadow, setShadow] = useState<string>("0px 0px 15px rgba(0,0,0,0.3)");
  const rotate = useTransform(x, [-200, 200], [-10, 10]);

  useEffect(() => {
    const unsubX = x.on("change", latestX => {
      const latestY = y.get();
      setShadow(`${latestX / 10}px ${latestY / 10}px 15px rgba(0,0,0,0.3)`);
    });

    return () => {
      unsubX();
    };
  }, [x, y]);

  function handleDragEnd(_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) {
    const threshold = 100;
    if (Math.abs(info.offset.x) > threshold) {
      const direction = info.offset.x > 0 ? 'right' : 'left';
      onSendToBack(direction);
    } else {
      x.set(0);
      y.set(0);
    }
  }

  return (
    <motion.div
      className="absolute inset-0 cursor-grab rounded-xl"
      style={{ 
        x, 
        y, 
        rotate,
        boxShadow: shadow,
      }}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={0.7}
      whileTap={{ cursor: "grabbing" }}
      onDragEnd={handleDragEnd}
      initial={false}
      layout
    >
      {children}
    </motion.div>
  );
}

interface SwipeCardsProps {
  cards: { id: number; z: number; img: string }[];
}

export default function SwipeCards({ cards }: SwipeCardsProps) {
  const [currentCards, setCards] = useState(cards);

  const moveToBack = () => {
    setCards((prevCards) => {
      if (prevCards.length <= 1) return prevCards;
      const [firstCard, ...rest] = prevCards;
      return [...rest, firstCard];
    });
  };

  return (
    <div className="relative h-80 w-80 mx-auto">
      {currentCards.map((card, index) => (
        <SwipeCard key={card.id} onSendToBack={moveToBack}>
          <motion.div
            className="h-full w-full rounded-xl overflow-hidden relative"
            initial={false}
            layout
          >
            <Image
              src={card.img}
              alt={`card-${card.id}`}
              fill
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              priority={index === 0}
              className="pointer-events-none object-cover"
            />
          </motion.div>
        </SwipeCard>
      ))}
    </div>
  );
}