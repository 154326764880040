/* eslint-disable react-hooks/rules-of-hooks */
'use client';

import React, { useRef, useState } from 'react';
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import Link from 'next/link';

const cn = (...args: (string | undefined)[]) => {
  return twMerge(clsx(args));
};

interface DockProps {
  className?: string;
  items?: {
    link?: string;
    Icon?: React.ReactNode;
    target?: string;
    defaultBgColor?: string;
    hoverBgColor?: string;
    tooltip?: string;
    label?: string;
  }[];
  position?: 'left' | 'right' | 'top' | 'bottom';
}

const Dock = ({ className = '', items = [], position = 'bottom' }: DockProps) => {
  const mouseX = useMotionValue(Infinity);
  const mouseY = useMotionValue(Infinity);

  const containerStyles = {
    left: 'fixed left-0 ml-6 top-1/2 transform -translate-y-1/2',
    right: 'fixed right-0 mr-6 top-1/2 transform -translate-y-1/2',
    top: 'fixed top-0 mt-6 left-1/2 transform -translate-x-1/2',
    bottom: 'fixed bottom-0 mb-6 left-1/2 transform -translate-x-1/2',
  }[position];

  const dockLayout = position === 'top' || position === 'bottom' ? 'flex-row' : 'flex-col';

  // Tooltip animation variants based on position
  const tooltipVariants = {
    top: { opacity: 1, y: 0 },
    bottom: { opacity: 1, y: 0 },
    left: { opacity: 1, x: 0 },
    right: { opacity: 1, x: 0 },
  };

  const tooltipInitial = {
    top: { opacity: 0, y: -10 },
    bottom: { opacity: 0, y: 10 },
    left: { opacity: 0, x: -10 },
    right: { opacity: 0, x: 10 },
  };

  const tooltipTransition = { duration: 0.3, ease: 'easeOut' };

  return (
    <div
      onMouseMove={(e) => {
        mouseX.set(e.pageX);
        mouseY.set(e.pageY);
      }}
      onMouseLeave={() => {
        mouseX.set(Infinity);
        mouseY.set(Infinity);
      }}
      className={cn(
        `flex ${dockLayout} items-center gap-4 sm:gap-5 md:gap-6 rounded-full bg-zinc-800/90 border border-white/10 backdrop-blur-sm p-4 shadow-lg shadow-black/25 ${containerStyles}`,
        className
      )}
    >
      {items.map((item, index) => {
        const ref = useRef<HTMLDivElement>(null);
        const [hovered, setHovered] = useState(false);

        let distance;
        if (position === 'top' || position === 'bottom') {
          distance = useTransform(mouseX, (val) => {
            const bounds = ref.current?.getBoundingClientRect() ?? { x: 0, width: 0 };
            return val - bounds.x - bounds.width / 2;
          });
        } else {
          distance = useTransform(mouseY, (val) => {
            const bounds = ref.current?.getBoundingClientRect() ?? { y: 0, height: 0 };
            return val - bounds.y - bounds.height / 2;
          });
        }

        const scaleSync = useTransform(distance, [-150, 0, 150], [0.8, 1.2, 0.8]);
        const scale = useSpring(scaleSync, { mass: 0.1, stiffness: 150, damping: 12 });

        // Tooltip position classes (You will need to adjust this based on the title size of your tooltip)
        const tooltipPositionClasses = {
          top: 'top-full mt-2 -left-2 transform -translate-x-1/2',
          bottom: 'bottom-full mb-2 -left-2 transform -translate-x-1/2',
          left: 'left-full ml-2 top-2 transform -translate-y-1/2',
          right: 'right-full mr-2 top-2 transform -translate-y-1/2',
        };

        return (
          <div key={index} className="relative">
            {item.tooltip && (
              <motion.div
                initial={tooltipInitial[position]}
                animate={hovered ? tooltipVariants[position] : tooltipInitial[position]}
                transition={tooltipTransition}
                className={`absolute whitespace-nowrap bg-zinc-800/95 text-white text-sm px-3 py-1.5 rounded-lg transition-opacity duration-300 backdrop-blur-sm border border-white/10 ${tooltipPositionClasses[position]}`}
                style={{ visibility: hovered ? 'visible' : 'hidden' }}
              >
                {item.tooltip}
              </motion.div>
            )}
            <motion.div
              ref={ref}
              style={{ scale }}
              className={`flex flex-col items-center gap-1`}
            >
              <motion.div
                className={`aspect-square h-12 w-12 sm:h-14 sm:w-14 rounded-full flex items-center justify-center transition-all duration-300 ${
                  hovered 
                    ? `${item.hoverBgColor || 'bg-zinc-700'} shadow-lg scale-110` 
                    : `${item.defaultBgColor || 'bg-zinc-700'} hover:shadow-md`
                }`}
                onHoverStart={() => setHovered(true)}
                onHoverEnd={() => setHovered(false)}
              >
                <Link
                  href={item.link || '#'}
                  target={item.target || '_self'}
                  className="flex items-center justify-center w-full h-full relative"
                >
                  <div className="absolute inset-0 bg-white/5 rounded-full animate-pulse" />
                  <div className="relative">
                    {item.Icon}
                  </div>
                </Link>
              </motion.div>
              {item.label && (
                <span className="text-xs text-white/80 font-medium">
                  {item.label}
                </span>
              )}
            </motion.div>
          </div>
        );
      })}
    </div>
  );
};

export default Dock;
