"use client";

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter } from "@/components/ui/card"
import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { Badge } from "@/components/ui/badge"
import "./styles/marquee.css"
import Link from "next/link"
import FeatureSection from "@/app/components/ui/Featuresection"
import BrandSection from "@/app/components/ui/Brandsection"
import { FaGoogle, FaApple, FaMeta, FaMicrosoft } from 'react-icons/fa6'
import { FaUser } from 'react-icons/fa'
import Dock from '@/app/components/ui/Dock'
import { LampDemo } from "@/components/ui/lamp";
import SwipeCards from "@/app/components/ui/Swipecard"
import { Heart, BadgeCheck, ShieldAlert, MapPin } from "lucide-react"
import { useState, useEffect } from 'react'
import { RainbowButton } from "@/components/ui/rainbow-button"
import { useFirebase } from "@/lib/context/firebase-context"
import { useRouter } from 'next/navigation'
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore'
import { db } from "@/lib/firebase"
import { useLikes } from '@/hooks/useLikes';

// Add this interface at the top of the file, after the imports
interface UserProfile {
  id: string;
  firstName?: string;
  lastName?: string;
  age?: string;
  occupation?: string;
  location?: string;
  photos?: string[];
  createdAt?: string;
  updatedAt?: string;
  [key: string]: any;
}

export default function Component() {
  const brands = [
    { name: 'Google', logo: FaGoogle },
    { name: 'Apple', logo: FaApple },
    { name: 'Meta', logo: FaMeta },
    { name: 'Microsoft', logo: FaMicrosoft },
  ];

  const dockItems = [
    {
      link: '/dashboard',
      Icon: <FaUser className="w-7 h-7 text-white" />,
      tooltip: 'View profiles',
      label: 'View profiles',
      defaultBgColor: 'bg-purple-600/80',
      hoverBgColor: 'bg-purple-500'
    }
  ];

  // Add these from dashboard
  const [verifiedStatuses, setVerifiedStatuses] = useState<boolean[]>([])
  
  useEffect(() => {
    setVerifiedStatuses(Array(4).fill(0).map(() => Math.random() > 0.5))
  }, [])

  const createCardImages = () => {
    const imageIds = [
      '1517841905240-472988babdf9',
      '1438761681033-6461ffad8d80',
      '1494790108377-be9c29b29330',
      '1534528741775-53994a69daeb'
    ];
    return imageIds.map(id => 
      `https://images.unsplash.com/photo-${id}?auto=format&fit=crop&w=400&h=500&q=75`
    );
  };

  const createCardData = (index: number) => {
    const images = createCardImages();
    return images.map((img, i) => ({
      id: index * 4 + i,
      z: images.length - i - 1,
      img: img
    }));
  };

  const { user } = useFirebase()
  const router = useRouter()

  const handleTalkButton = (index: number) => {
    if (!user) {
      router.push('/auth')
    } else {
      router.push(`/messages?new=${index + 1}&name=Alexandra`)
    }
  }

  // Add profiles state and fetching logic
  const [profiles, setProfiles] = useState<UserProfile[]>([])

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const profilesRef = collection(db, 'users')
        const q = query(
          profilesRef,
          orderBy('createdAt', 'desc'),
          limit(4) // Only fetch 4 profiles for the featured section
        )
        
        const querySnapshot = await getDocs(q)
        const fetchedProfiles = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt || new Date().toISOString(),
          updatedAt: doc.data().updatedAt || doc.data().createdAt || new Date().toISOString()
        }))
        
        setProfiles(fetchedProfiles)
      } catch (error) {
        console.error('Error fetching profiles:', error)
      }
    }

    fetchProfiles()
  }, [])

  const { likes, toggleLike } = useLikes(profiles.map(p => p.id));

  return (
    <div className="min-h-screen bg-gradient-to-b from-neutral-950 to-neutral-900 text-white">
      {/* Hero Section */}
      <LampDemo />

      {/* Add FeatureSection here, replacing the current Features Section */}
      <FeatureSection />

      {/* Profiles Section - Updated */}
      <div className="py-24 px-4 bg-neutral-900">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-semibold text-center mb-16 text-white">Featured Profiles</h2>
          <div className="grid md:grid-cols-4 gap-6">
            {profiles.map((profile, index) => (
              <Card key={profile.id} className="overflow-hidden relative">
                <div className="w-full h-full">
                  <SwipeCards 
                    cards={(() => {
                      const photos = profile.photos || [];
                      return photos.length > 0
                        ? photos.map((photo: string, i: number) => ({
                            id: i,
                            z: photos.length - i - 1,
                            img: photo
                          }))
                        : createCardData(index);
                    })()}
                  />
                  <div className="absolute top-2 right-2 z-10 scale-90 sm:scale-100">
                    {verifiedStatuses[index] ? (
                      <Badge variant="secondary" className="bg-green-500/20 text-green-500 border-green-500/20">
                        <BadgeCheck className="h-3 w-3 mr-1" />
                        <span className="hidden sm:inline">Verified</span>
                        <span className="sm:hidden">✓</span>
                      </Badge>
                    ) : (
                      <Badge variant="secondary" className="bg-yellow-500/20 text-yellow-500 border-yellow-500/20">
                        <ShieldAlert className="h-3 w-3 mr-1" />
                        <span className="hidden sm:inline">Unverified</span>
                        <span className="sm:hidden">!</span>
                      </Badge>
                    )}
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 z-10">
                    <div className="p-3 sm:p-4 space-y-1 sm:space-y-2 bg-gradient-to-t from-black/60 to-transparent">
                      <div className="flex items-center gap-1 sm:gap-2">
                        <Badge variant="secondary" className="text-sm">{profile.age || '?'}</Badge>
                        <Badge variant="outline" className="text-sm">
                          <MapPin className="h-3 w-3 mr-1" />
                          {profile.location || 'Unknown'}
                        </Badge>
                      </div>
                      <h3 className="text-base sm:text-lg font-semibold text-white">
                        {profile.firstName} {profile.lastName}
                      </h3>
                      <p className="text-xs sm:text-sm text-gray-300">{profile.occupation || 'No occupation listed'}</p>
                    </div>
                    <CardFooter className="flex justify-between p-2 sm:p-4 bg-white dark:bg-gray-800">
                      <div className="flex gap-1 sm:gap-2">
                        <Button 
                          variant="ghost" 
                          size="icon" 
                          className="h-9 w-9 sm:h-10 sm:w-10"
                          onClick={() => {
                            if (!user) {
                              router.push('/auth');
                              return;
                            }
                            toggleLike(profile.id);
                          }}
                        >
                          <div className="relative">
                            <Heart 
                              className={`h-4 w-4 sm:h-5 sm:w-5 transition-colors ${
                                likes[profile.id]?.isLiked ? 'fill-current text-red-500' : ''
                              }`} 
                            />
                            {likes[profile.id]?.count > 0 && (
                              <span className="absolute -top-2 -right-2 text-xs bg-primary text-primary-foreground rounded-full px-1">
                                {likes[profile.id]?.count}
                              </span>
                            )}
                          </div>
                        </Button>
                      </div>
                      <RainbowButton 
                        onClick={() => handleTalkButton(index)}
                        className="text-sm sm:text-base px-3 sm:px-4 py-2"
                      >
                        Talk To {profile.firstName || 'HER'}
                      </RainbowButton>
                    </CardFooter>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>

      {/* Testimonials Marquee */}
      <div className="py-24 overflow-hidden border-y border-neutral-700">
        <div className="flex gap-8 animate-marquee">
          {[
            {
              initial: 'M',
              name: 'Michael',
              title: 'Finally found genuine connection',
              review: 'After trying multiple dating apps, this platform helped me meet someone truly compatible. The AI matching is incredibly accurate!',
              avatar: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=100&h=100&q=80'
            },
            {
              initial: 'D',
              name: 'David',
              title: 'Life-changing experience',
              review: 'Met my fiancée here 8 months ago. The personality matching algorithm really understands what matters in a relationship.',
              avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=100&h=100&q=80'
            },
            {
              initial: 'J',
              name: 'James',
              title: 'Worth every minute',
              review: 'The quality of matches is outstanding. No more endless swiping - every conversation has been meaningful and worthwhile.',
              avatar: 'https://images.unsplash.com/photo-1531384441138-2736e62e0919?auto=format&fit=crop&w=100&h=100&q=80'
            },
            {
              initial: 'R',
              name: 'Robert',
              title: 'Exceeded expectations',
              review: 'As a busy professional, I appreciate how the AI filters helped me find someone who truly matches my lifestyle and values.',
              avatar: 'https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?auto=format&fit=crop&w=100&h=100&q=80'
            },
            {
              initial: 'T',
              name: 'Thomas',
              title: 'Game-changing platform',
              review: "The verification system and AI matching made me feel secure. Met my girlfriend here, and we couldn't be happier together.",
              avatar: 'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?auto=format&fit=crop&w=100&h=100&q=80'
            }
          ].map((review, index) => (
            <Card key={index} className="bg-neutral-800 border-neutral-700 w-[300px] shrink-0">
              <CardContent className="p-6">
                <div className="flex items-start gap-4">
                  <Avatar>
                    <img 
                      src={review.avatar} 
                      alt={review.name}
                      className="h-full w-full object-cover"
                    />
                    <AvatarFallback className="bg-primary text-primary-foreground">
                      {review.initial}
                    </AvatarFallback>
                  </Avatar>
                  <div className="space-y-1">
                    <h4 className="font-medium text-white">{review.title}</h4>
                    <p className="text-sm text-neutral-200">
                      {review.review}
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-24 px-4">
        <div className="max-w-4xl mx-auto text-center space-y-12">
          <div className="space-y-4">
            <h2 className="text-4xl font-bold text-white">Ready to Find Your Match?</h2>
            <p className="text-xl text-neutral-200">
              Join thousands of happy couples who found their perfect match on our platform.
            </p>
          </div>
          
          <Link href="/auth" className="inline-block mt-8">
            <Button 
              size="lg" 
              className="rounded-full bg-gradient-to-r from-violet-600 to-indigo-600 hover:from-violet-500 hover:to-indigo-500 text-white font-semibold px-8 py-6 text-lg shadow-lg hover:shadow-indigo-500/25 transition-all duration-300"
            >
              Create Your Profile
            </Button>
          </Link>
        </div>
      </div>

      {/* Brand Section */}
      <BrandSection brands={brands} />

      {/* Add Dock */}
      <Dock 
        items={dockItems} 
        position="bottom" 
        className="z-50 mb-8" 
      />
    </div>
  )
}